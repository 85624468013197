'use strict';
var $ = require(1),
	// _ = require('underscore'),
	// TweenMax = require('gsap'),
	plugins = require(3);
	// f4 = require('F4');


function Main() {
// =================================================
// = Private variables (example: var _foo = bar; ) =
// =================================================
}
var _desktop = false;




// =================================================
// = public functions                              =
// =================================================
Main.prototype.init = function()
{
	_desktop = ( 'ontouchstart' in window ? false : true );

	$('.up').each(function(i)
	{
		var up = $(this);
		setTimeout(function()
		{
			up.removeClass('up');
		}, 750*i);
	});
}




// ================================================
// = Private functions (function _private () {} ) =
// ================================================




// Some startup stuff.
var main = new Main();
module.exports = main;
// global.window.F4 = f4;
$(document).ready(main.init);
